/* Generated by Font Squirrel (http://www.fontsquirrel.com) */

/*
 * Roboto
================================================================================ */
@font-face {
    font-family: 'roboto-black';
    src: url('../fonts/roboto/roboto-black-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-black-italic';
    src: url('../fonts/roboto/roboto-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-bold';
    src: url('../fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-bold-italic';
    src: url('../fonts/roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-italic';
    src: url('../fonts/roboto/roboto-italic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-light';
    src: url('../fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-light-italic';
    src: url('../fonts/roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-medium';
    src: url('../fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-medium-italic';
    src: url('../fonts/roboto/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-regular';
    src: url('../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-thin';
    src: url('../fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-thin-italic';
    src: url('../fonts/roboto/roboto-thinitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*
 * Domine
================================================================================ */
@font-face {
    font-family: 'domine-bold';
    src: url('../fonts/domine/domine-bold-webfont.woff2') format('woff2'),
         url('../fonts/domine/domine-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'domine-regular';
    src: url('../fonts/domine/domine-regular-webfont.woff2') format('woff2'),
         url('../fonts/domine/domine-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
